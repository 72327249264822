.main-home{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.panel_mint{
    width: 400px;
    padding: 20px;
    margin:auto;
    background: rgba(216, 169, 228, 20%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(234, 165, 236, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }