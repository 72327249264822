.main_about{
    height: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

  .panel_about{
    background: transparent;
    margin:auto;
    text-align: right;
  }

  .panel_card{
    background: transparent;
    margin:auto;
    text-align: right;
  }


  .panel_about h1{
    font-size: 2em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .panel_card h1{
    font-size: 2em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 940px){

    .panel_about{
      margin: 30px;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 495px) {

    .panel_about{
      margin: 10px;
      text-align: center;
    }
  }