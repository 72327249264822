@import url(https://fonts.googleapis.com/css2?family=Love+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100&display=swap);
.App {
  text-align: center;
}

.main-home{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.panel_mint{
    width: 400px;
    padding: 20px;
    margin:auto;
    background: rgba(216, 169, 228, 20%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(234, 165, 236, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
.panel_desc{
    
    background: transparent;
    margin:auto;
    /*border-radius: 16px;
    box-shadow: 0 4px 30px rgba(234, 165, 236, 0.1);
      backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);*/
  }

  .welcome{
      padding: 20px;
  }

  .welcome h1{
    font-size: 2em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.navbar{
    width: 100%;
    height: 90px;
    /*background-color: #000*/;
}

.navbar .container{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.navbar .nav{
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
}

.navbar .icon{
    color: var(--primary-pink);
    font-size: 2rem;
}

.menu-list{
    padding: 1rem;
}

.navbar h1, a{
    color: #fff;
}

.logo{
    margin-left: -20px;
    margin-top: -20px;
}

.hamburger{
    display: none;
}

@media screen and (max-width:940px) {
    .hamburger{
        display: block;
    }

    .nav{
        position: absolute;
        flex-direction: column;
        justify-content: start!important;
        align-items: center;
        top:90px;
        left: -200%;
        width: 100%;
        height: 100vh;
        background-color: #000;
        transition: 0.3s ease-in-out;
    }

    .nav.active{
        left:0;
        z-index: 9;
    }

    .nav a {
        font-size: 1.4rem;
    }
}

.profile_card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(248, 0, 165, 0.993);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .profile_card:hover {
    box-shadow: 0 8px 16px 0 rgba(248, 0, 165, 0.993);
  }
  
  /* Add some padding inside the card container */
  .profile_info {
    padding: 2px 16px;
  }

  .profile_info h1{
    font-size: 4em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.main_team{
    height: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

  .panel_team{
    background: transparent;
    margin:auto;
    text-align: right;
  }
  
  @media screen and (max-width: 940px){

    .panel_team{
      margin: 30px;
    }
  }
  
  @media screen and (max-width: 495px) {

    .panel_team{
      margin: 10px;
    }
  }
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    /*border: 1px solid #f1f1f1;*/
    -webkit-perspective: 1000px;
            perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    animation: flip 1s;
    -webkit-animation: flip 1s;
  }
  
  @-webkit-keyframes flip {
    from {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg)
    }
    to {
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg)
    }
  }
  @keyframes flip {
    from {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg)
    }
    to {
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg)
    }
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    text-align: center;
    padding: 10%;
    color: white;
    background: rgba(216, 169, 228, 20%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(255, 253, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .flip-card-front p{
      padding-top: 15px;
  }

  /* Style the back side */
  .flip-card-back {
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

.main_about{
    height: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

  .panel_about{
    background: transparent;
    margin:auto;
    text-align: right;
  }

  .panel_card{
    background: transparent;
    margin:auto;
    text-align: right;
  }


  .panel_about h1{
    font-size: 2em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .panel_card h1{
    font-size: 2em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 940px){

    .panel_about{
      margin: 30px;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 495px) {

    .panel_about{
      margin: 10px;
      text-align: center;
    }
  }
* {
    box-sizing:border-box;
    margin:0;
    padding:0;
}

:root {
  --primary-purple: #732794;
  --primary-pink: #D68FD6;
}

.pink {
    color: #D68FD6;
    color: var(--primary-pink);
}

body {
  background:#000;
  color:#fff;
  margin: 0;
  font-family: 'Big Shoulder Text', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background { 
  position:absolute;
  display:block; 
  top: 0;
  left: 0;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
    list-style-type:none;
}

a {
    text-decoration:none;
}

h2 {
    font-size: 3rem;
}

.btn {
    padding: 10px 28px;
    font-size: 1.2rem;
    background-image: linear-gradient(45deg, #732794 0%, #D68FD6 100%);
    background-image: linear-gradient(45deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-family:'Fredericka the Great', sans-serif;
}

.btn-outline {
    background: transparent;
    border: 1px solid #D68FD6;
    border: 1px solid var(--primary-pink);
    color: #D68FD6;
    color: var(--primary-pink);
}

.panel{
  margin: 50px 150px;
  background: rgba(216, 169, 228, 20%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(234, 165, 236, 0.1);
    backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 940px){
  h1 {
      font-size:2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  .panel{
    margin: 10px;
  }
}

@media screen and (max-width: 495px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .panel{
    margin: 10px;
  }
}



