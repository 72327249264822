.panel_desc{
    
    background: transparent;
    margin:auto;
    /*border-radius: 16px;
    box-shadow: 0 4px 30px rgba(234, 165, 236, 0.1);
      backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);*/
  }

  .welcome{
      padding: 20px;
  }

  .welcome h1{
    font-size: 2em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }