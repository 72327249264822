.profile_card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(248, 0, 165, 0.993);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .profile_card:hover {
    box-shadow: 0 8px 16px 0 rgba(248, 0, 165, 0.993);
  }
  
  /* Add some padding inside the card container */
  .profile_info {
    padding: 2px 16px;
  }

  .profile_info h1{
    font-size: 4em;
    background: -webkit-linear-gradient(180deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }