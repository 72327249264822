.main_team{
    height: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

  .panel_team{
    background: transparent;
    margin:auto;
    text-align: right;
  }
  
  @media screen and (max-width: 940px){

    .panel_team{
      margin: 30px;
    }
  }
  
  @media screen and (max-width: 495px) {

    .panel_team{
      margin: 10px;
    }
  }