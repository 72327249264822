@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100&display=swap');

* {
    box-sizing:border-box;
    margin:0;
    padding:0;
}

:root {
  --primary-purple: #732794;
  --primary-pink: #D68FD6;
}

.pink {
    color: var(--primary-pink);
}

body {
  background:#000;
  color:#fff;
  margin: 0;
  font-family: 'Big Shoulder Text', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background { 
  position:absolute;
  display:block; 
  top: 0;
  left: 0;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
    list-style-type:none;
}

a {
    text-decoration:none;
}

h2 {
    font-size: 3rem;
}

.btn {
    padding: 10px 28px;
    font-size: 1.2rem;
    background-image: linear-gradient(45deg, var(--primary-purple) 0%, var(--primary-pink) 100%);
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-family:'Fredericka the Great', sans-serif;
}

.btn-outline {
    background: transparent;
    border: 1px solid var(--primary-pink);
    color: var(--primary-pink);
}

.panel{
  margin: 50px 150px;
  background: rgba(216, 169, 228, 20%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(234, 165, 236, 0.1);
    backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 940px){
  h1 {
      font-size:2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  .panel{
    margin: 10px;
  }
}

@media screen and (max-width: 495px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .panel{
    margin: 10px;
  }
}


