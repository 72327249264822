@import url('https://fonts.googleapis.com/css2?family=Love+Light&display=swap');

.navbar{
    width: 100%;
    height: 90px;
    /*background-color: #000*/;
}

.navbar .container{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.navbar .nav{
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
}

.navbar .icon{
    color: var(--primary-pink);
    font-size: 2rem;
}

.menu-list{
    padding: 1rem;
}

.navbar h1, a{
    color: #fff;
}

.logo{
    margin-left: -20px;
    margin-top: -20px;
}

.hamburger{
    display: none;
}

@media screen and (max-width:940px) {
    .hamburger{
        display: block;
    }

    .nav{
        position: absolute;
        flex-direction: column;
        justify-content: start!important;
        align-items: center;
        top:90px;
        left: -200%;
        width: 100%;
        height: 100vh;
        background-color: #000;
        transition: 0.3s ease-in-out;
    }

    .nav.active{
        left:0;
        z-index: 9;
    }

    .nav a {
        font-size: 1.4rem;
    }
}
